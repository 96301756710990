<template>
  <div id="chartBlock">
    <div class="text-center">
      <RadioGroup
        v-model="showType"
        type="button"
        size="small"
        @on-change="handleChangeShowType"
      >
        <Radio
          v-for="item in showTypeArray"
          :key="'showType_'+item.value"
          :label="item.value"
        >{{item.name}}</Radio>
      </RadioGroup>
    </div>
    <div
      v-show="showChart"
      class="p-t-20"
      style="width:100%;height:280px;"
      id="chart"
    >
    </div>
    <div
      v-show="!showChart"
      class="default-content"
    >
      <p style="font-size: x-large;">暂无数据</p>
    </div>

  </div>
</template>

<script>
import * as echarts from 'echarts'
import { listMicroResourceSaleDayGbStation, listMicroAmountAndConversionRateGb } from '@/api/dw/micro'
export default {
  data () {
    return {
      showTypeArray: [
        { value: 1, name: '站点' },
        { value: 2, name: '行业' }
      ],
      showType: 1,
      publisherId: this.$store.getters.token.userInfo.publisherId,
      showChart: false
    }
  },
  computed: {
    startDate () {
      return this.$store.state.microSituation.startDate
    },
    endDate () {
      return this.$store.state.microSituation.endDate
    },
    dateRange () { // 日期范围
      return `${this.$store.state.microSituation.startDate}/${this.$store.state.microSituation.endDate}`
    }
  },
  // created () {
  //   this.getStationGroupData()
  // },
  mounted () {
    this.$nextTick(() => {
      document.getElementById('chartBlock')
    })
  },
  methods: {
    handleChangeShowType () {
      switch (this.showType) {
        case 1:
          this.getStationGroupData()
          break
        case 2:
          this.getIndustryGroupData()
          break
      }
    },
    /**
     * 获取站点图标数据
     */
    getStationGroupData () {
      const query = {
        publisherId: this.publisherId,
        startDate: this.startDate,
        endDate: this.endDate
      }
      this.showChart = false
      listMicroResourceSaleDayGbStation(query).then(res => {
        if (res && !res.errcode && res.length) {
          this.showChart = true
          const legendData = ['小微客户数', '去化率']
          const xData = res.map(x => x.name)
          const yAxis = [
            {
              type: 'value',
              name: '数量',
              min: 0
            },
            {
              type: 'value',
              name: '去化率',
              min: 0,
              axisLabel: {
                formatter: '{value} %'
              }
            }
          ]
          const seriesData = [
            {
              name: '小微客户数',
              type: 'bar',
              data: res.map(x => x.number)
            },
            {
              name: '去化率',
              type: 'line',
              yAxisIndex: 1,
              data: res.map(x => x.rate)
            }
          ]
          this.initChart(legendData, xData, yAxis, seriesData)
        } else {
          this.initChart()
        }
      })
    },
    /**
     * 获取行业分组数据
     */
    getIndustryGroupData () {
      const query = {
        publisherId: this.publisherId,
        startDate: this.startDate,
        endDate: this.endDate,
        contractFeature: 2,
        gbType: 'inter_industry'
      }
      this.showChart = false
      listMicroAmountAndConversionRateGb(query).then(res => {
        if (res && !res.errcode && res.length) {
          this.showChart = true
          const legendData = ['新签数', '签约金额', '转化率']
          const xData = res.map(x => x.name)
          const yAxis = [
            {
              type: 'value',
              name: '数量',
              position: 'left',
              min: 0,
              axisLine: {
                show: true
              }
            },
            {
              type: 'value',
              name: '金额（元）',
              min: 0,
              position: 'right',
              axisLine: {
                show: true
              },
              axisLabel: {
                formatter: '{value}'
              }
            },
            {
              type: 'value',
              name: '转化率',
              min: 0,
              position: 'right',
              offset: 80,
              axisLine: {
                show: true
              },
              axisLabel: {
                formatter: '{value} %'
              }
            }
          ]
          const seriesData = [
            {
              name: '新签数',
              type: 'bar',
              data: res.map(x => x.actualNumber)
            },
            {
              name: '签约金额',
              type: 'bar',
              yAxisIndex: 1,
              data: res.map(x => x.value)
            },
            {
              name: '转化率',
              type: 'line',
              yAxisIndex: 2,
              data: res.map(x => x.rate)
            }
          ]
          this.initChart(legendData, xData, yAxis, seriesData)
        } else {
          this.initChart()
        }
      })
    },
    initChart (legendData = [], xData = [], yAxis = [], seriesData = []) {
      this.$nextTick(() => {
        let chart = echarts.getInstanceByDom(document.getElementById('chart'))
        if (chart == null) { // 如果不存在，就进行初始化
          chart = echarts.init(document.getElementById('chart'))
        }
        if (this.showChart) {
          const option = {
            grid: {
              left: '6%',
              right: '6%'
            },
            textStyle: {
              color: '#fff'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                crossStyle: {
                  color: '#999'
                }
              }
            },
            legend: {
              data: legendData,
              textStyle: {
                color: '#fff'
              }
            },
            xAxis: [
              {
                type: 'category',
                data: xData,
                axisPointer: {
                  type: 'shadow'
                },
                axisLabel: {
                  interval: 0,
                  rotate: 40,
                  color: '#c5c8ce'
                }
              }
            ],
            yAxis,
            series: seriesData
          }

          chart.setOption(option, true)
          window.addEventListener('resize', function () {
            chart.resize()
          })
        } else {
          chart.dispose()
        }
      })
    }
  },
  watch: {
    dateRange (val) {
      if (val) {
        this.handleChangeShowType()
      }
    }
  }
}
</script>
